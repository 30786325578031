import { useState, useRef, useEffect, useCallback } from 'react'
import styles from "./Connect.module.scss";
import { isMobile, isChrome, isChromium, isSafari, isIE, isEdge, isOpera } from "react-device-detect"
import classnames from "classnames/bind";
import { Modal, Icon, Button, CursorTooltip, FlexContainer, Flex } from "../../components";
import useOutsideClick from "../../utils/outsideClickCallback";
import { shorteningAddress } from '../../utils';
import { SubscribeType } from '../../../custom-definitions/everscale';
import copy from 'copy-to-clipboard';

import {
    Address,
    AssetType,
    Contract,
    ContractState,
    FullContractState,
    hasEverscaleProvider,
    Permissions,
    ProviderRpcClient,
    Subscription,
} from 'everscale-inpage-provider';

const cnb = classnames.bind(styles);

export interface IConnect {
  disabled?: boolean,
  onConnect?: (props: any) => void,
  onDisconnect?: (props?: any) => void,
}

export const Connect = ({onConnect, onDisconnect, disabled, ...props}: IConnect) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [copied, setCopied] = useState<boolean>(false);

  const [hasSurfKeeper, setHasSurfKeeper] = useState<boolean>(false);
  const [hasEverWallet, setHasEverWallet] = useState<boolean>(false);
  const [everWalletContract, setEverWalletContract] = useState<any>(false);
  const [everWalletSubscription, setEverWalletSubscription] = useState<Subscription<'contractStateChanged'> | undefined>();
  const [walletAddress, setWalletAddress] = useState<string>('');
  const [walletBalance, setWalletBalance] = useState<string>('');
  const refPicker = useRef<HTMLDivElement>(null);

  const [connected, setConnected] = useState<boolean>(false);
  const [showDisconnect, setShowDisconnect] = useState<boolean>(false);
  
  const refListener = useRef<any>();

  const ever = new ProviderRpcClient();

  useEffect(() => {
    const hasProvider = async () => {
      return await ever.hasProvider(); 
    }
    hasProvider()
      .then((value: boolean) => setHasEverWallet(value));
  }, []);

  useEffect(() => {
    if (window.everscale?.isSurf) {
      setHasSurfKeeper(true);
    }
  }, [window.everscale]);

  useEffect(() => {
    if (hasSurfKeeper && !disabled) {
      window.everscale.checkConnect().then((res => {
        if (res && res.address) {
          setWalletAddress(res.address);
          setConnected(true);
          if (onConnect) onConnect({address: res.address});

          refListener.current = window.everscale.subscribe({
            type: 'balance' as SubscribeType,
            address: res.address, 
            listener: (balance) => {
              setWalletBalance(balance);
            }
          });
        }
      }))
    }
  }, [hasSurfKeeper]);

  const connectSurfKeeper = useCallback(async (wallet: string) => {
    const res = await window.everscale.connect();

    if (res && res.isConnected && res.address) {
      setWalletAddress(res.address);
      setConnected(true);
      setShowModal(false);
      if (onConnect) onConnect({address: res.address});

      window.everscale.subscribe({
        type: 'balance' as SubscribeType,
        address: res.address, 
        listener: (balance) => {
          setWalletBalance(balance);
        }
      });
    }
  }, []);

  const connectEverWallet = useCallback(async (wallet: string) => {
    debugger;
    await ever.ensureInitialized();
    const { accountInteraction } = await ever.requestPermissions({
      permissions: ['basic', 'accountInteraction'],
    });
    debugger;
    if (accountInteraction == null) {
      throw new Error('Insufficient permissions');
    }


    if (accountInteraction && accountInteraction.address) {
      setWalletAddress(accountInteraction.address.toString());
      setConnected(true);
      setShowModal(false);
      if (onConnect) onConnect({address: accountInteraction.address.toString()});

      const fullContractState = await ever.getFullContractState({
        address: accountInteraction.address,
      })

      setEverWalletContract(fullContractState.state);
    }
  }, []);

  useEffect(() => {
    console.log(everWalletContract?.balance);
    const subscribe = async (address: string) => {
      return await ever.subscribe(
        'contractStateChanged',
        { address: new Address(address) },
      )
    }
    if (everWalletContract && walletAddress) {
      subscribe(walletAddress)
        .then((subscription) => setEverWalletSubscription(subscription));
      everWalletSubscription && everWalletSubscription.on('data', (event: any) => {
        setEverWalletContract(event.state);
    })
    }
  }, [everWalletContract]);

  const disconnectSurfKeeper = useCallback(async () => {
    console.log("Disconnecting...");
    const res = await window.everscale.disconnect();
    console.log("Connected status: " + res);
    if (res && !res.isConnected) {
      setWalletAddress('');
      setConnected(false);
      setShowModal(false);
      setShowDisconnect(false);

      if (onDisconnect) onDisconnect();

      if (refListener.current) {
        refListener.current?.remove();
      }
    }
  }, []);


  const extensions: any[] = [
    {
      name: "Surf Keeper",
      icon: <><svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" fill="#4963E6"/>
      <path d="M20 18.3334V6.66669L12 13.6667V25.3334L20 18.3334Z" fill="white" stroke="white" strokeLinejoin="round"/>
      </svg>
      </>,
      address: !hasSurfKeeper ? "Download" : "Detected",
      disabled: !hasSurfKeeper,
      onPress: connectSurfKeeper,
    },
    {
      name: "Ever Wallet",
      icon: <><svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 16C0 7.16344 7.16344 0 16 0V0C24.8366 0 32 7.16344 32 16V16C32 24.8366 24.8366 32 16 32V32C7.16344 32 0 24.8366 0 16V16Z" fill="#050B2E"/>
      <path d="M12.9524 9.33331L6.66667 15.619H16.381V25.3333L22.6667 19.0476L22.6667 9.33331H12.9524Z" fill="#CBE3F2" stroke="#CAE3F1" strokeLinejoin="round"/>
      </svg>
      </>,
      address: !hasEverWallet ? "Download" : "Detected",
      disabled: !hasEverWallet,
      onPress: connectEverWallet
    },
  ]

  useOutsideClick(refPicker, () => {});

  return (isMobile || isSafari || isIE || isEdge || isOpera 
    ? <a href="https://ever.surf/download" target="_blank">
        <Button
          className={cnb("pattern-connect")}
          color="default"
          size="large"
          iconRight={{
            animation: "left",
            icon: <Icon icon="ever-surf" />
          }}
        >
          Get Surf app
        </Button>
      </a> 
    : <>
        {!connected && <Button
            className={cnb("pattern-connect")}
            color="primary"
            size="large"
            disabled={disabled}
            onClick={() => {
              if (!disabled) setShowModal(true);
            }}
          >
            Connect
        </Button>}

        {connected && <div
          className={cnb("pattern", "pattern-connect")}>
        
          <FlexContainer
            justify="space-between"
            align="center"
            className={cnb("tooltip-container")}
          >
            <CursorTooltip content={copied ? "Copied!" : "Tap to copy address"}><Flex
              onClick={() => {
                setCopied(true);
                setTimeout(() => {
                  setCopied(false);
                }, 1000);
                copy(walletAddress, {
                  debug: true,
                  message: 'Press #{key} to copy',
                })
              }}
            >
              <FlexContainer
                justify="flex-start"
                align="flex-start"
                direction="column"
              >
                <Flex className={cnb("pattern-amount", "paragraph", "paragraph-normal" )}>{walletBalance}&thinsp;Ē</Flex>
                <Flex className={cnb("pattern-address", "paragraph", "paragraph-small")}>
                  {shorteningAddress(walletAddress)}
                </Flex>
              </FlexContainer>
            </Flex></CursorTooltip>
            <CursorTooltip content={"Tap to disconnect"}><Flex>

              <Button
                color="transparent"
                size="medium"
                disabled={false}
                onClick={() => {
                  setShowDisconnect(true);
                  setTimeout(() => setShowDisconnect(false), 2500);
                }}
                iconLeft={{
                  icon: <Icon icon="close"/>,
                  animation: "none"
                }}
              ></Button>
            </Flex></CursorTooltip>
          </FlexContainer>
        

          <Button
            className={cnb("pattern-connect", "pattern-connect-button", {"pattern-connect-button-active": showDisconnect})}
            color="failure"
            size="large"
            disabled={false}
            onClick={disconnectSurfKeeper}
          >
            Disconnect
          </Button>
        </div>}
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          className={cnb("modal-connect")}
          body={
            <>
              <h4 className="title title-large">Connect a wallet<br/> on Everscale to continue</h4>
              <div className={cnb("wallet-list", {"wallet-list-empty": !extensions.length})}>
                
                {extensions.length
                  ? extensions.map((extension, index) => (extension.disabled && extension.name === "Surf Keeper")
                    ? <a key={extension.name} href="https://ever.surf/download" target="_blank"><FlexContainer
                    className={cnb("extension", {"extension-disabled": extension.disabled && extension.name !== "Surf Keeper"})}
                    justify="space-between"
                    align="center"
                  >
                    <Flex
                      className={cnb("extension-icon")}
                      grow={0}
                    >{extension.icon}</Flex>
                    <Flex
                      className={cnb("action", "action-normal")}
                      grow={1}
                    >{extension.name}</Flex>
                    <Flex
                      className={cnb("extension-address", "paragraph", "paragraph-normal")}
                      grow={0}
                    >{extension.address}</Flex>
                  </FlexContainer></a>
                    : <FlexContainer
                    key={extension.name}
                    className={cnb("extension", {"extension-disabled": extension.disabled && extension.name !== "Surf Keeper"})}
                    justify="space-between"
                    align="center"
                    onClick={extension.onPress}
                  >
                    <Flex
                      className={cnb("extension-icon")}
                      grow={0}
                    >{extension.icon}</Flex>
                    <Flex
                      className={cnb("action", "action-normal")}
                      grow={1}
                    >{extension.name}</Flex>
                    <Flex
                      className={cnb("extension-address", "paragraph", "paragraph-normal")}
                      grow={0}
                    >{extension.address}</Flex>
                  </FlexContainer>)
                  : "No wallets yet"}
              </div>
              <span className="paragraph paragraph-medium">or continue using the dapp and confirming actions securely with Everlink</span>
            </>
          }
          header={<></>}
          fullscreen="sm-down"
        />
    </>
  );
};

export default Connect;
