
/* Global Dependencies */
import classnames from "classnames/bind";
import { useEffect, useState } from 'react';
import { Container } from "react-bootstrap";
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import DebotClient from '../../client/client';

import { Button, Flex, FlexContainer, Icon, Skeleton, StatsSkeleton, Toast } from '../../components';
import { useRates } from '../../hooks/useRates';
import { Connect } from '../../patterns';
import { poolSelector, walletState } from '../../store';
import { TPool, TPoolConfig, TStaked } from '../../types';
import { fromUnit64ToDate, numberFormat, toNano, toNanoAndFormat } from '../../utils';
import BoostedDebotQrCode from './BoostedDebotQrCode';
import GoshDebotQrCode from './GoshDebotQrCode';
import styles from "./Pool.module.scss";

/* Component */
const cnb = classnames.bind(styles);

export const Pool = () => {
  const navigate = useNavigate();

  const { pool } = useParams<{ pool: string }>();
  const currentPool = useRecoilValue(poolSelector(pool));
  const [wallet, setWallet] = useRecoilState(walletState);
  const [toast, setToast] = useState<boolean>(true);

  const [stake, setStake] = useState<number>(0);
  const [inputStake, setInputStake] = useState<string>("0");
  const [fill, setFill] = useState<string>("0.0");

  const [staked, setStaked] = useState<Array<TStaked>>([]);
  const [showEachStake, setShowEachStake] = useState<boolean>(false);

  useEffect(() => {
    if (currentPool) {
      if (wallet.connected) {
        DebotClient.getUserStakes(currentPool?.poolAddr).then((res) => {
          console.log(res);
          if (res && res?.stakes) {
            setStaked(res.stakes);
          }
        })
      } else {
        setStaked([]);
      }
    }
  }, [currentPool, wallet.connected]);

  useEffect(() => {
    if (currentPool) {
      const filled = Number(((BigInt(currentPool.config.capacity) - BigInt(currentPool?.remainingCapacity)) * BigInt(10000) / BigInt(currentPool.config.capacity))) / 100;
      const fixedFilled = filled.toString().match(/^\d+(?:\.\d{0,1})?/);
      if (fixedFilled && +fixedFilled) {
        setFill((+fixedFilled).toFixed(1));
      } else {
        console.log(1)
        setFill(filled.toFixed(1));
      }
    }
  }, [currentPool]);

  const shareToTwitter = () => {
    const apy = currentPool?.maxAPY;
    const url = "https://staking.surf/";
    const text = `I stake my $EVER using the @ever_surf app at ${apy} APY! Here's how I earn in #everscale on `;
    window.open('http://twitter.com/share?url=' + encodeURIComponent(url) + '&text=' + encodeURIComponent(text), '', 'left=0,top=0,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0');
  }

  return (<>
    <Container fluid className={cnb("content-container container-overflow-hidden")}>
      <FlexContainer
        direction="row"
        justify="space-between"
        align="stretch"
        className={cnb("stake-header")}
      >

        <Flex grow={1}>
          <Button
            size="large"
            className="back-btn" onClick={(e) => navigate('/')}>
            <Icon icon={"arrow-left"} />
          </Button>
        </Flex>
      </FlexContainer>
      <div className="inner-page-wrapper">
        <FlexContainer
          direction="row"
          justify="space-between"
          align="stretch"
        >
          <Flex grow={1} className={cnb("pool-title")}>
            {currentPool?.poolName ? currentPool?.poolName : <Skeleton />}
          </Flex>
        </FlexContainer>
        <FlexContainer
          direction="row"
          justify="space-between"
          align="stretch"
        >
          <Flex grow={1}>
            <div className={cnb("card", "card-" + currentPool?.uniqueName)}>
              <FlexContainer
                direction="row"
                justify="space-between"
                align="center"
              >
                <Flex grow={1} className={cnb("card-body")}>
                  <div className={cnb("card-category")}>
                    {currentPool && currentPool.uniqueName === 'gosh' && (
                      <>
                        Earn {currentPool?.rewardTokenName}
                      </>
                    )}
                    {currentPool && currentPool.uniqueName === 'boosted' && (
                      <>
                        Estimated rewards
                      </>
                    )}
                    {!currentPool && (
                      <Skeleton wide='triplet' />
                    )}
                  </div>
                  <div className={cnb("card-title")}>
                    {currentPool && currentPool.uniqueName === 'gosh' && (
                      <>
                        <span>{currentPool?.minAPY.replace('%', '')}—{currentPool?.maxAPY}</span> <span>APR</span>
                      </>
                    )}
                    {currentPool && currentPool.uniqueName === 'boosted' && (
                      <>
                        <span>up to {currentPool?.maxAPY}</span> <span>APR</span>
                      </>
                    )}
                    {!currentPool && (
                      <Skeleton />
                    )}

                  </div>
                  <div className={cnb("card-subtitle")}>
                    {currentPool && currentPool.uniqueName === 'gosh' && (
                      <>Exclusive</>
                    )}
                    {currentPool && currentPool.uniqueName === 'boosted' && (
                      <>12.00% + booster</>
                    )}
                    {!currentPool && (
                      <Skeleton wide='half' />
                    )}
                  </div>
                </Flex>
                <Flex grow={0}>
                  <Button className={cnb("share-btn")} color="transparent" onClick={() => shareToTwitter()}>
                    <Icon icon={"twitter"} /> Share
                  </Button>
                </Flex>
              </FlexContainer>
            </div>
          </Flex>
        </FlexContainer>
        <FlexContainer
          direction="row"
          justify="space-between"
          align="stretch"
        >
          <Flex grow={1}>
            <div className={cnb("card")}>
              <FlexContainer
                direction="row"
                justify="space-between"
                align="center"
              >
                <Flex grow={1} className={cnb("card-body")}>
                  <div className={cnb("card-category")}>
                    {currentPool &&
                      <>Remaining capacity</>
                    }
                    {!currentPool && (
                      <Skeleton wide='half' />
                    )}
                  </div>
                  <div className={cnb("card-title")}>
                    {currentPool && currentPool.uniqueName === 'gosh' && (
                      <>{toNanoAndFormat(+currentPool?.remainingCapacity, 2, 2)} {currentPool?.stakeTokenName}</>
                    )}
                    {currentPool && currentPool.uniqueName === 'boosted' && (
                      <>{toNanoAndFormat(+currentPool?.remainingCapacity, 2, 2)} {currentPool?.stakeTokenName}</>
                    )}
                    {!currentPool && (
                      <Skeleton />
                    )}
                  </div>
                  <div className={cnb("card-subtitle")}>
                    {currentPool && (
                      <>{fill}% filled</>
                    )}
                    {!currentPool && (
                      <Skeleton wide='half' />
                    )}
                  </div>
                </Flex>
              </FlexContainer>
            </div>
          </Flex>
        </FlexContainer>
        {wallet.connected && <>
          {/* Available deposit */}
          {currentPool && currentPool.uniqueName === 'boosted' &&
            <>
              <FlexContainer
                direction="row"
                justify="space-between"
                align="stretch"
              >
                <Flex grow={1}>
                  <div className={cnb("card", "card-disabled")}>
                    <FlexContainer
                      direction="row"
                      justify="space-between"
                      align="center"
                    >
                      <Flex grow={1} className={cnb("card-body")}>
                        <div className={cnb("card-category")}>
                          Available deposit
                        </div>
                        <div className={cnb("card-title")}>
                          <span>0.00</span> <span>EVER</span>
                        </div>
                        <div className={cnb("card-subtitle")}>
                          <span>0.00</span> <span>USD</span>
                        </div>
                      </Flex>
                      <Flex grow={0}>
                        <Button disabled color="primary" className={cnb("unstake-btn")} onClick={() => navigate("/unstake")}>Unstake</Button>
                      </Flex>
                    </FlexContainer>
                  </div>
                </Flex>
              </FlexContainer>
            </>
          }
          {currentPool && currentPool.uniqueName === 'gosh' &&
            <>
              <FlexContainer
                direction="row"
                justify="space-between"
                align="stretch"
              >
                <Flex grow={1}>
                  <div className={cnb("card", "card-disabled")}>
                    <FlexContainer
                      direction="row"
                      justify="space-between"
                      align="center"
                    >
                      <Flex grow={1} className={cnb("card-body")}>
                        <div className={cnb("card-category")}>
                          Available deposit
                        </div>
                        <div className={cnb("card-title")}>
                          <span>0.00</span> <span>EVER</span>
                        </div>
                        <div className={cnb("card-subtitle")}>
                          <span>0.00</span> <span>USD</span>
                        </div>
                      </Flex>
                      <Flex grow={0}>
                        <Button disabled color="primary" className={cnb("unstake-btn")} onClick={() => navigate("/unstake")}>Withdraw</Button>
                      </Flex>
                    </FlexContainer>
                  </div>
                </Flex>
              </FlexContainer>
            </>
          }
          {/* End Available deposit */}
          {currentPool &&
            <Stakes currentPool={currentPool} stakes={staked} />
          }
          {currentPool &&
            <Rewards currentPool={currentPool} stakes={staked} />
          }
        </>
        }
        {/* <FlexContainer
          direction="row"
          justify="space-between"
          align="stretch"
        >
          <Flex grow={1}>
            <div className={cnb("card")}>
              <FlexContainer
                direction="row"
                justify="space-between"
                align="center"
              >
                <Flex grow={1} className={cnb("card-body")}>
                  <div className={cnb("card-category")}>
                    Unstaking
                  </div>
                  <div className={cnb("card-title")}>
                    100.00 EVER
                  </div>
                  <div className={cnb("card-subtitle")}>
                    <span>258.20</span> <span>USD</span>
                  </div>
                </Flex>
              </FlexContainer>
            </div>
          </Flex>
        </FlexContainer> */}
        {/* <FlexContainer
          direction="row"
          justify="space-between"
          align="stretch"
        >
          <Flex grow={1}>
            <FlexContainer
              direction="row"
              justify="space-between"
              align="center"
              className={cnb("stake-card")}
            >
              <Flex grow={1}>
                <FlexContainer
                  direction="column"
                  justify="space-between"
                  align="stretch"
                >
                  <Flex grow={1} className={cnb("stake-input-label")}>
                    <label htmlFor="stake-val">Amount</label>
                  </Flex>
                  <Flex grow={1} className={cnb("stake-input")}>
                    <input id="stake-val" type="number" value={inputStake} onBlur={(ev) => onStakeBlur(ev.target.value)} onChange={(ev) => onStakeChange(ev.target.value)} />
                  </Flex>
                </FlexContainer>

              </Flex>
              <Flex grow={0} className={cnb("currency")}>EVER</Flex>
            </FlexContainer>
            <FlexContainer
              direction="row"
              justify="space-between"
              align="stretch"
            >
              <Flex grow={1} className={cnb("stake-card-desc")}>min. 100 — 1,000,000 max.</Flex>
            </FlexContainer>
          </Flex>
        </FlexContainer> */}
        <FlexContainer
          direction="row"
          justify="space-between"
          align="stretch"
        >
          <Flex grow={1}>
            <Button color="primary" size="large" disabled={!currentPool} value={stake} onClick={(ev) => navigate(`/stake/${pool}`)} className={cnb("stake-btn")}>Stake {currentPool?.stakeTokenName}</Button>
          </Flex>
        </FlexContainer>
      </div>
    </Container>
    {currentPool && currentPool.uniqueName === 'gosh' &&
      <Toast
        header={<div className="title title-small2">
          <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 13.7142V6.00254C14 6.00163 13.9989 6.00119 13.9983 6.00185L10 10.2858V17.9975C10 17.9984 10.0011 17.9988 10.0017 17.9981L14 13.7142Z" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinejoin="round" />
            <path d="M21 13.7142V6.00254C21 6.00163 20.9989 6.00119 20.9983 6.00185L17 10.2858V17.9975C17 17.9984 17.0011 17.9988 17.0017 17.9981L21 13.7142Z" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinejoin="round" />
            <path d="M7 13.7142V6.00254C7 6.00163 6.99889 6.00119 6.99827 6.00185L3 10.2858V17.9975C3 17.9984 3.00111 17.9988 3.00173 17.9981L7 13.7142Z" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinejoin="round" />
          </svg>Web 3.0 compatible</div>}

        qrcode={<GoshDebotQrCode />}

        close={true}
        variant={"dark"}
        show={toast}
        onClose={() => { setToast(false) }}
      >
        <a href="https://uri.ever.surf/debot/0:a06b2c6aa85bba895e356193a100e192c649edbcefe8eead511804c9fb8521cb" rel="noopener noreferrer nofollow" target="_blank">
          <div className="action action-normal">Gosh Pool</div>
          <div className="parapgraph parapgraph-small">Address: 0:a06 ···· 21cb</div>
        </a>
      </Toast>
    }
    {currentPool && currentPool.uniqueName === 'boosted' &&
      <Toast
        header={<div className="title title-small2">
          <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 13.7142V6.00254C14 6.00163 13.9989 6.00119 13.9983 6.00185L10 10.2858V17.9975C10 17.9984 10.0011 17.9988 10.0017 17.9981L14 13.7142Z" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinejoin="round" />
            <path d="M21 13.7142V6.00254C21 6.00163 20.9989 6.00119 20.9983 6.00185L17 10.2858V17.9975C17 17.9984 17.0011 17.9988 17.0017 17.9981L21 13.7142Z" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinejoin="round" />
            <path d="M7 13.7142V6.00254C7 6.00163 6.99889 6.00119 6.99827 6.00185L3 10.2858V17.9975C3 17.9984 3.00111 17.9988 3.00173 17.9981L7 13.7142Z" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinejoin="round" />
          </svg>Web 3.0 compatible</div>}

        qrcode={<BoostedDebotQrCode />}

        close={true}
        variant={"dark"}
        show={toast}
        onClose={() => { setToast(false) }}
      >
        <a href="https://uri.ever.surf/debot/0:e07fc1a5bed9a9c4ab4dd4c75b323c8e7cda28e571258cf4208f1a0dad818112" rel="noopener noreferrer nofollow" target="_blank">
          <div className="action action-normal">Boosted Stake</div>
          <div className="parapgraph parapgraph-small">Address: 0:e07 ···· 8112</div>
        </a>
      </Toast>
    }
  </>
  );
};
//

export const Stakes = ({
  currentPool,
  stakes
}: {
  currentPool: TPool,
  stakes: Array<TStaked>
}) => {
  const { rates } = useRates();
  const [showEachStake, setShowEachStake] = useState<boolean>(false);

  const displayRate = (coin: number, rate: string): string => {
    return numberFormat(coin * (+rate), 2, 2);
  };

  return (<>
    {/* Stakes */}
    {currentPool && stakes && stakes.length > 1 && !showEachStake &&
      <FlexContainer
        direction="row"
        justify="space-between"
        align="stretch"
      >
        <Flex grow={1}>
          <div className={cnb("card", "card-stack")}>
            <FlexContainer
              direction="row"
              justify="space-between"
              align="center"
            >
              <Flex grow={1} className={cnb("card-body")}>
                <div className={cnb("card-category")}>
                  Locked deposit
                </div>
                <div className={cnb("card-title")}>
                  {toNanoAndFormat(stakes.map(el => +el.amount).reduce((a, b) => a + b), 2, 2)} {currentPool?.stakeTokenName}
                </div>
                <div className={cnb("card-subtitle")}>
                  <span>{displayRate(toNano(stakes.map(el => +el.amount).reduce((a, b) => a + b)), rates.priceStr)}</span> <span>USD</span>
                </div>
              </Flex>

              <Flex grow={0}>
                <Button color="primary" className={cnb("unstake-btn")} onClick={() => setShowEachStake(true)}>View</Button>
              </Flex>
            </FlexContainer>
          </div>
        </Flex>
      </FlexContainer>
    }
    {currentPool && stakes && stakes.length > 1 && showEachStake &&
      stakes.map((stake, index) => <FlexContainer
        key={index}
        direction="row"
        justify="space-between"
        align="stretch"
      >
        <Flex grow={1}>
          <div className={cnb("card", "clickable")} onClick={() => setShowEachStake(false)}>
            <FlexContainer
              direction="row"
              justify="space-between"
              align="center"
            >
              <Flex grow={1} className={cnb("card-body")}>
                <div className={cnb("card-category")}>
                  Locked deposit
                </div>
                <div className={cnb("card-title")}>

                  {toNanoAndFormat(+stake.amount, 2, 2)} {currentPool?.stakeTokenName}
                </div>
                <div className={cnb("card-subtitle")}>
                  {currentPool.uniqueName === 'gosh' &&
                    <>
                      <span>{fromUnit64ToDate(stake.createdAt, +stake?.lockSecs)}</span> · <span>{`${+stake?.yield / 10}%`} APR</span>
                    </>}
                  {currentPool.uniqueName === 'boosted' && <>
                    <span>{fromUnit64ToDate(stake.createdAt, +stake?.lockSecs)}</span> · <span>{`${+stake?.yield / 10}%`} booster</span>
                  </>
                  }
                </div>
              </Flex>
            </FlexContainer>
          </div>
        </Flex>
      </FlexContainer>)
    }
    {currentPool && stakes && stakes.length === 1 &&
      stakes.map((stake, index) => <FlexContainer
        key={index}
        direction="row"
        justify="space-between"
        align="stretch"
      >
        <Flex grow={1}>
          <div className={cnb("card")}>
            <FlexContainer
              direction="row"
              justify="space-between"
              align="center"
            >
              <Flex grow={1} className={cnb("card-body")}>
                <div className={cnb("card-category")}>
                  Locked deposit
                </div>
                <div className={cnb("card-title")}>

                  {toNanoAndFormat(+stake.amount, 2, 2)} {currentPool?.stakeTokenName}
                </div>
                <div className={cnb("card-subtitle")}>
                  {currentPool.uniqueName === 'gosh' &&
                    <>
                      <span>{fromUnit64ToDate(stake.createdAt, +stake?.lockSecs)}</span> · <span>{`${+stake?.yield / 10}%`} APR</span>
                    </>}
                  {currentPool.uniqueName === 'boosted' && <>
                    <span>{fromUnit64ToDate(stake.createdAt, +stake?.lockSecs)}</span> · <span>{`${+stake?.yield / 10}%`} booster</span>
                  </>
                  }
                </div>
              </Flex>
            </FlexContainer>
          </div>
        </Flex>
      </FlexContainer>)
    }
    {currentPool && stakes.length === 0 &&
      <FlexContainer
        direction="row"
        justify="space-between"
        align="stretch"
      >
        <Flex grow={1}>
          <div className={cnb("card", "card-disabled")}>
            <FlexContainer
              direction="row"
              justify="space-between"
              align="center"
            >
              <Flex grow={1} className={cnb("card-body")}>
                <div className={cnb("card-category")}>
                  <>Locked deposit</>
                </div>
                <div className={cnb("card-title")}>
                  0.00 EVER
                </div>
                <div className={cnb("card-subtitle")}>
                  <>0.00 USD</>
                </div>
              </Flex>
            </FlexContainer>
          </div>
        </Flex>
      </FlexContainer>
    }
    {/* End Stakes */}
  </>)
};

export const Rewards = ({
  currentPool,
  stakes
}: {
  currentPool: TPool,
  stakes: Array<TStaked>
}) => {
  const [expectedReward, setExpectedReward] = useState<string>("");
  useEffect(() => {
    if (currentPool && stakes.length > 0) {
      let reward = 0;
      stakes.forEach(stake => {
        reward += +stake.reward;
      });
      setExpectedReward(toNanoAndFormat(reward, 2, 2));
    }
  }, [currentPool, stakes]);
  return (<>

    {/* Accrued rewards */}
    {currentPool && currentPool.uniqueName === 'gosh' && stakes.length === 0 &&
      <>
        <FlexContainer
          direction="row"
          justify="space-between"
          align="stretch"
        >
          <Flex grow={1}>
            <div className={cnb("card", "card-disabled")}>
              <FlexContainer
                direction="row"
                justify="space-between"
                align="center"
              >
                <Flex grow={1} className={cnb("card-body")}>
                  <div className={cnb("card-category")}>
                    Accrued rewards
                  </div>
                  <div className={cnb("card-title")}>
                    <span>0.00</span> <span>{currentPool?.rewardTokenName}</span>
                  </div>
                  <div className={cnb("card-subtitle")}>
                    <span>will start no later than 31 July</span>
                  </div>
                </Flex>
              </FlexContainer>
            </div>
          </Flex>
        </FlexContainer>
      </>
    }
    {currentPool && currentPool.uniqueName === 'gosh' && stakes.length > 0 &&
      <>
        <FlexContainer
          direction="row"
          justify="space-between"
          align="stretch"
        >
          <Flex grow={1}>
            <div className={cnb("card")}>
              <FlexContainer
                direction="row"
                justify="space-between"
                align="center"
              >
                <Flex grow={1} className={cnb("card-body")}>
                  <div className={cnb("card-category")}>
                    Expected rewards
                  </div>
                  <div className={cnb("card-title")}>
                    <span>{expectedReward}</span> <span>{currentPool?.rewardTokenName}</span>
                  </div>
                  <div className={cnb("card-subtitle")}>
                    <span>will start no later than 31 July</span>
                  </div>
                </Flex>
              </FlexContainer>
            </div>
          </Flex>
        </FlexContainer>
      </>
    }
    {/* End Accrued rewards */}
  </>)
};
export default Pool;
