/* Global Dependencies */
import classnames from "classnames/bind";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import {
  Mousewheel,
  FreeMode
} from 'swiper';
import 'swiper/swiper.scss'; // core Swiper

/* Local Dependencies */
import DebotClient from '../../client/client';
import { Button, Flex, FlexContainer, Icon, Skeleton } from "../../components";
import { usePools } from "../../hooks";
import { useRates } from '../../hooks/useRates';
import { walletState } from '../../store';
import { TPool, TStaked } from '../../types';
import { numberFormat, toNano, toNanoAndFormat } from '../../utils';
import styles from "./Dashboard.module.scss";

/* Component */
const cnb = classnames.bind(styles);

const StakedCard = ({
  name,
  pool,
  stakes
}: {
  name: string,
  pool: TPool,
  stakes: Array<TStaked>,
}) => {
  const navigate = useNavigate();
  const { rates } = useRates();

  const displayRate = (coin: number, rate: string): string => {
    return numberFormat(coin * (+rate), 2, 2);
  }

  return (<div
    onClick={() => pool?.active ? navigate(`/pool/${pool?.uniqueName}`) : console.log('Inactive click')}
    className={cnb("card", { "card-mobile": isMobile }, { "card-disable": !pool?.active }, "card-" + pool?.uniqueName)}
  >
    <FlexContainer
      direction="row"
      justify="space-between"
      align="stretch"
      className={cnb("main-flex")}
    >
      <Flex grow={1}>
        <FlexContainer
          justify="flex-start"
          align="center"
          direction="row"
          className={cnb("main-flex")}
        >
          <Flex
            grow={10}
            className={cnb("card-header", "action", "action-normal")}
          >
            <div className={cnb("card-category")}>
              {pool?.poolName}
            </div>
            <div className={cnb("card-title")}>
              {stakes && stakes.length > 0 && (<>
                {toNanoAndFormat(stakes.map(el => +el.amount).reduce((a, b) => a + b))} {pool?.stakeTokenName}
              </>)}
            </div>
            <div className={cnb("card-subtitle")}>
              {stakes && stakes.length > 0 && (<>
                {displayRate(toNano(stakes.map(el => +el.amount).reduce((a, b) => a + b)), rates.priceStr)} USD
              </>)}
            </div>
          </Flex>
          <Flex
            grow={0}
            className={cnb("card-value")}
          >
          </Flex>
        </FlexContainer>
      </Flex>
      <Flex
        shrink={0}
      >
      </Flex>
    </FlexContainer>
  </div>);
};

const BoostCard = ({
  active,
  stakeTokenName,
  poolName,
  rewardTokenName,
  rewardTokenType,
  minAPY,
  maxAPY,
  uniqueName,
}: {
  active: boolean,
  stakeTokenName: string,
  poolName: string,
  rewardTokenName: string,
  rewardTokenType: string,
  minAPY: string,
  maxAPY: string,
  uniqueName: string,
}) => {
  const navigate = useNavigate();
  return (<div
    onClick={() => active ? navigate(`/pool/${uniqueName}`) : console.log('Inactive click')}
    className={cnb("card", { "card-mobile": isMobile }, { "card-disable": !active }, "card-" + uniqueName)}
  >
    <FlexContainer
      direction="row"
      justify="space-between"
      align="stretch"
      className={cnb("main-flex")}
    >
      <Flex grow={1}>
        <FlexContainer
          justify="flex-start"
          align="flex-start"
          direction="column"
          className={cnb("main-flex")}
        >
          <Flex
            grow={10}
            className={cnb("card-header", "action", "action-normal")}
          >
            <div className={cnb("card-category")}>
              {active &&
                stakeTokenName
              }
              {!active &&
                "Soon"
              }
            </div>
            <div className={cnb("card-title")}>{poolName ? poolName : <Skeleton />}</div>
            <div className={cnb("card-subtitle")}>
              {rewardTokenName ? `Get ${rewardTokenName}` : <Skeleton />} <span>{rewardTokenType ? rewardTokenType : ''}</span>
            </div>
            {uniqueName === 'gosh' &&
              <div className={cnb("limited-offer")}>
                Exclusive
              </div>
            }
            {active && uniqueName === 'boosted' &&
              <div className={cnb("limited-offer")}>
                Limited offer
              </div>
            }
          </Flex>
          {active &&
            <Flex
              grow={0}
              className={cnb("card-value")}
            >
              {maxAPY ? <>up to {maxAPY}</> : <Skeleton />}
            </Flex>
          }
        </FlexContainer>
      </Flex>
      <Flex
        shrink={0}
      >
      </Flex>
    </FlexContainer>
  </div>);
};

const GrowCard = ({
  active,
  poolId,
  category,
  title,
  subtitle,
  subtitle2,
  value,
  minAPY,
  maxAPY,
  uniqueName,
  className
}: {
  active: boolean,
  poolId?: string,
  category?: string,
  title?: string,
  subtitle?: string,
  subtitle2?: string,
  value?: string,
  minAPY?: string,
  maxAPY?: string,
  uniqueName?: string,
  className?: string,
}) => {
  const navigate = useNavigate();
  return (<div
    onClick={() => active ? navigate(`/validate/${uniqueName}`) : console.log('Inactive click')}
    className={cnb("card", { "card-mobile": isMobile }, { "card-disable": !active }, "card-category-" + category, className)}
  >
    <FlexContainer
      direction="row"
      justify="space-between"
      align="stretch"
      className={cnb("main-flex")}
    >
      <Flex grow={1}>
        <FlexContainer
          justify="flex-start"
          align="flex-start"
          direction="column"
          className={cnb("main-flex")}
        >
          <Flex
            grow={10}
            className={cnb("card-header", "action", "action-normal")}
          >
            <div className={cnb("card-title")}>{title ? title : ''}<br />{subtitle ? subtitle : ''}</div>
          </Flex>
          {!active && <Flex
            grow={0}
            className={cnb("card-value")}
          >
            {value}
          </Flex>}

          {active &&
            <Flex
              grow={0}
              className={cnb("card-value")}
            >
              {maxAPY ? <>{minAPY?.replaceAll("%", "")}&mdash;{maxAPY}</> : <Skeleton />}
            </Flex>
          }
        </FlexContainer>
      </Flex>
      <Flex
        shrink={0}
      >
      </Flex>
    </FlexContainer>
  </div>);
};

const PromoSlider = ({
  showTips
}: {
  showTips: boolean
}) => {
  const [show, setShow] = useState<boolean>(true);

  useEffect(() => {
    let showPromoValue = sessionStorage.getItem("showPromo");
    if (showPromoValue == null) {
      showPromoValue = "true";
    }
    setShow(JSON.parse(showPromoValue));
  }, []);

  const hidePromo = () => {
    sessionStorage.setItem("showPromo", "false");
    setShow(false);
  }
  return (
    <>
      {/* <div className={cnb("promo-title")}>Earn up to <strong>24.0%</strong> yield easily <span className={cnb("like")}></span> interest income from <em>an old school bank</em></div> */}
      <div className={cnb("promo-title")}>Stake and earn yields simple and fast</div>
      {(show && showTips) &&
        <div className={cnb("promo-box")}>
          <FlexContainer
            direction="column"
            justify="flex-start"
            align="stretch"
          >
            <Flex>
              <FlexContainer
                justify="space-between"
                align="center"
                className=""
              >
                <Flex
                  grow={1}
                  className="title title-large"
                >
                  How it works
                </Flex>
              </FlexContainer>
            </Flex>
            <Flex
              shrink={0}
            >
              <Swiper
                className={cnb("slider")}
                slidesPerView={"auto"}
                spaceBetween={16}
                mousewheel={{
                  forceToAxis: true,
                  sensitivity: 0.5
                }}
                freeMode={true}
                modules={[FreeMode, Mousewheel]}
              >
                <SwiperSlide
                  className={cnb("promo-slider-slide", isMobile ? "promo-slider-slide-mobile" : "")}
                >
                  <div className={cnb("card", "card-promo", "card-promo-one", { "card-mobile": isMobile })}>
                    <FlexContainer
                      direction="row"
                      justify="space-between"
                      align="stretch"
                      className={cnb("main-flex")}
                    >
                      <Flex>
                        <FlexContainer
                          direction="column"
                          justify="start"
                          align="top"
                          className={cnb("main-flex", "promo-inner")}
                        >
                          <Flex className={cnb("promo-inner-point")}>
                            •
                          </Flex>
                          <Flex className={cnb("promo-inner-title")}>
                            Install the wallet
                          </Flex>
                          <Flex className={cnb("promo-inner-desc")}>
                            e.g. <a href="https://ever.surf/download">Ever Surf</a>
                          </Flex>
                        </FlexContainer>
                      </Flex>
                      <Flex>
                        <FlexContainer
                          direction="column"
                          justify="start"
                          align="top"
                          className={cnb("main-flex", "promo-inner")}
                        >
                          <Flex className={cnb("promo-inner-point")}>
                            ••
                          </Flex>
                          <Flex className={cnb("promo-inner-title")}>
                            Deposit $EVER
                          </Flex>
                          <Flex className={cnb("promo-inner-desc")}>
                            <a href="https://pay.ever.surf">Buy</a>, <a href="https://payments.surf/getever">Exchange</a>, <br />
                            <a href="https://payments.surf/swap">Swap</a> or <a href="https://octusbridge.io/">Transfer</a>
                          </Flex>
                        </FlexContainer>
                      </Flex>
                    </FlexContainer>
                  </div>
                </SwiperSlide>
                <SwiperSlide
                  className={cnb("promo-slider-slide", isMobile ? "promo-slider-slide-mobile" : "")}
                >
                  <div className={cnb("card", "card-promo", "card-promo-two", { "card-mobile": isMobile })}>
                    <FlexContainer
                      direction="row"
                      justify="space-between"
                      align="stretch"
                      className={cnb("main-flex")}
                    >
                      <Flex>
                        <FlexContainer
                          direction="column"
                          justify="start"
                          align="stretch"
                          className={cnb("main-flex", "promo-inner")}
                        >
                          <Flex className={cnb("promo-inner-point")}>
                            •
                          </Flex>
                          <Flex className={cnb("promo-inner-title")}>
                            Choose a pool
                          </Flex>
                          <Flex className={cnb("promo-inner-desc")}>
                            one
                          </Flex>
                        </FlexContainer>
                      </Flex>
                      <Flex>
                        <FlexContainer
                          direction="column"
                          justify="start"
                          align="stretch"
                          className={cnb("main-flex", "promo-inner")}
                        >
                          <Flex className={cnb("promo-inner-point")}>
                            ••
                          </Flex>
                          <Flex className={cnb("promo-inner-title")}>
                            Make a stake
                          </Flex>
                          <Flex className={cnb("promo-inner-desc")}>
                            two
                          </Flex>
                        </FlexContainer>
                      </Flex>
                      <Flex>
                        <FlexContainer
                          direction="column"
                          justify="start"
                          align="stretch"
                          className={cnb("main-flex", "promo-inner")}
                        >
                          <Flex className={cnb("promo-inner-point")}>
                            •••
                          </Flex>
                          <Flex className={cnb("promo-inner-title")}>
                            Enjoy profits
                          </Flex>
                          <Flex className={cnb("promo-inner-desc")}>
                            three
                          </Flex>
                        </FlexContainer>
                      </Flex>
                    </FlexContainer>
                  </div>
                </SwiperSlide>

                <SwiperSlide
                  className={cnb("promo-slider-slide", isMobile ? "promo-slider-slide-mobile" : "")}
                >

                  <Button
                    className={cnb("promo-close-btn")}
                    onClick={hidePromo}
                    type="button"
                    size="large"
                    color="default"
                    iconLeft={{
                      icon: <Icon icon="close" className={cnb("promo-close-btn-svg")} />,
                      animation: "none"
                    }}
                  ></Button>
                </SwiperSlide>
              </Swiper>
            </Flex>
          </FlexContainer>
        </div>
      }
    </>)
};

const Slider = ({
  type,
  title,
  cards,
  className,
}: {
  type: "staked" | "boost" | "grow",
  title: string,
  cards: any,
  className?: string,
}) => {
  return <div className={cnb("slider", className)}>
    <FlexContainer
      direction="column"
      justify="flex-start"
      align="stretch"
    >
      <Flex>
        <FlexContainer
          justify="space-between"
          align="center"
          className=""
        >
          <Flex
            grow={1}
            className="title title-large"
          >
            {title}
          </Flex>
        </FlexContainer>
      </Flex>
      <Flex
        shrink={0}
      >
        <Swiper
          className={cnb("slider")}
          breakpoints={{
            640: {
              width: 640,
              slidesPerView: 3,
            },
            768: {
              width: 768,
              slidesPerView: 4,
            },
          }}
          slidesPerView={"auto"}
          spaceBetween={16}
          mousewheel={{
            forceToAxis: true,
            sensitivity: 0.5
          }}
          freeMode={true}
          modules={[FreeMode, Mousewheel]}
        >
          {cards.map((card: any, index: number) => <SwiperSlide
            key={index}
            className={cnb("slider-slide", isMobile ? "slider-slide-mobile" : "slider-slide-" + cards.length)}
          >
            {type === "staked" &&
              <StakedCard {...card} />
            }
            {type === "boost" &&
              <BoostCard {...card} />
            }
            {type === "grow" &&
              <GrowCard active={card.active} poolId={card.poolId} category={card.category} title={card.title} subtitle={card.subtitle} subtitle2={card.subtitle2} value={card.value} className={card.class} {...card} />
            }
          </SwiperSlide>
          )}
        </Swiper>
      </Flex>

    </FlexContainer>
  </div>
};
type TPoolStakes = {
  name: string;
  pool: TPool;
  stakes: Array<TStaked>;
}
type TAllPoolsStaked = Array<TPoolStakes>;

export const Dashboard = () => {
  const { pools } = usePools();
  const [wallet, setWallet] = useRecoilState(walletState);
  const [stakes, setStakes] = useState<TAllPoolsStaked>([]);
  const [connected, setConnected] = useState<boolean>(false);

  useEffect(() => {
    if (window.everscale?.isSurf) {
      window.everscale.checkConnect().then((res => {
        if (res && res.address) {
          setConnected(true);
        }
      }))
    }
  }, []);

  useEffect(() => {
    console.log('WALLET', wallet);
    if (wallet.connected) {
      if (pools && pools.length > 0) {
        pools.forEach(pool => {
          if (pool && pool.poolAddr) {
            DebotClient.getUserStakes(pool.poolAddr).then((res) => {
              console.log(res);
              if (res && res?.stakes && res?.stakes.length > 0) {
                let poolStakes: TPoolStakes = {
                  name: pool.uniqueName,
                  pool: pool,
                  stakes: res?.stakes
                };

                setStakes(stks => {
                  const addedObjectIdx = stks.findIndex(el => el.name === pool.uniqueName);
                  if (addedObjectIdx !== -1) {
                    stks[addedObjectIdx] = poolStakes;
                    return [...stks];
                  } else {
                    return [...stks, poolStakes];
                  }
                });
              }
            });
          }
        });
      }
    } else {
      setStakes([]);
    }
  }, [wallet, wallet.connected, pools]);

  const placeholders = Array(5).fill({
    active: false,
  });

  const grow = [{
    active: false,
    type: "grow",
    title: "Validate",
    subtitle: "Gosh",
    value: "Soon",
    class: "bg6"
  }, {
    active: false,
    type: "grow",
    title: "Validate",
    subtitle: "Everscale",
    value: "Soon",
    class: "bg7"
  }, {
    active: false,
    type: "grow",
    title: "Become",
    subtitle: "Relayer",
    value: "Soon",
    class: "bg8"
  }];
  return (<>
    <Container fluid className={cnb("content-container container-overflow-hidden")}>
      <PromoSlider showTips={stakes.length === 0} />
      {stakes.length > 0 &&
        <Slider type="staked" className="staked" title="Staked pools" cards={stakes} />
      }
      <Slider type="boost" className="boost" title="Boost earnings" cards={pools && pools.length > 0 ? pools : placeholders} />
      <Slider type="grow" className="grow" title="Grow funds" cards={pools && pools.length > 0 ? [{ ...pools.find((pool) => pool.poolName.toLowerCase() === "gosh pool"), ...grow[0], active: true }, ...grow.slice(1)] : grow} />
    </Container>
  </>
  );
};

export default Dashboard;
